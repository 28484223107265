.scrollTopBtn{
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 24px;
  bottom: 24px;
  z-index: 996;
  background: #ffc451;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  border: none;
  box-shadow: none;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  i{
    font-size: 28px;
    color: #151515;
    line-height: 0;
  }
  &:hover{
    transform: scale(1.05);
  }
  &.active{
    visibility: visible;
    opacity: 1
  }
}