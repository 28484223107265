.homepage{
  padding-top: 76px;
  section{
    overflow: hidden;
    .section-title{
      padding-bottom: 40px;
      h2{
        font-size: 18px;
        font-weight: bold;
        padding: 0;
        margin: 0 0 5px 0;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #000000;
        font-family: "Poppins", sans-serif;
        &:after{
          content: "";
          width: 120px;
          height: 2px;
          display: inline-block;
          background: #ffbb38;
          margin: 4px 10px;
        }
      }
      p{
        margin: 0;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: "Poppins", sans-serif;
        color: #151515;
      }
    }
  }
  .section1{
    width: 100%;
    height: 100vh;
    background: url(../../assets/images/solar_panel_home.jpg) fixed top center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
      content: "";
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }
    @media (max-width: 991px) {
      background-attachment: unset;
    }
    @media (max-width: 768px){
      height: auto;
      padding-top: 60px;
      padding-bottom: 30px;
    }
    //padding-top: 90px;
    .section1-info{
      .title{
        margin: 0;
        font-size: 56px;
        font-weight: 700;
        line-height: 64px;
        color: #fff;
        font-family: "Poppins", sans-serif;
        text-align: center;
        @media (max-width: 768px){
          font-size: 28px;
          line-height: 36px;
        }
      }
      .desc{
        color: rgba(255, 255, 255, 0.9);
        margin: 10px 0 0 0;
        font-size: 24px;
        text-align: center;
        @media (max-width: 768px){
          font-size: 20px;
          line-height: 24px;
        }
      }
      .card-wrapper{
        display: inline;
        .icon-box{
          padding: 30px 20px;
          transition: ease-in-out 0.3s;
          border: 1px solid rgba(255, 255, 255, 0.3);
          height: 100%;
          text-align: center;
          cursor: pointer;
          &:hover{
            border-color: #ffc451;
            transform: scale(1.05);
          }
          i{
            font-size: 32px;
            line-height: 1;
            color: #ffc451;
          }
          h3{
            font-family: 'Raleway', sans-serif;
            font-weight: 700;
            margin: 10px 0 0 0;
            padding: 0;
            font-size: 20px;
            line-height: 26px;
            color: #fff;
          }
        }
      }

    }
  }
  .section2{
    padding: 60px 0;
    img{
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% !important;
    }
    .content{
      h3 {
        font-weight: 700;
        font-size: 27px;
        font-family: "Raleway", sans-serif;
      }
      ul {
        list-style: none;
        padding: 0;
        li{
          padding: 0 0 8px 26px;
          position: relative;
          i{
            position: absolute;
            font-size: 20px;
            left: 0;
            top: -3px;
            color: #ffc451;
          }
        }
      }
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .warrantiesSection{
    padding-bottom: 30px;
  }
  .section3{
    width: 100%;
    position: relative;
    padding-bottom: 0px;
  }
  .servicesSection{
    padding: 60px 0;
    .service-cart{
      margin-bottom: 15px;
      .service-image{
        overflow: hidden;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        margin: 0 auto 20px;
        span{
          width: 100%;
          height: 100%;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h4{
        color: #ffbb38;
        text-align: center;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 28px;
      }
      p{
        font-size: 15px;
        text-align: center;
        margin: 0;
      }
    }
  }
  .section4{
    padding-top: 20px;
    padding-bottom: 30px;
    .image{
      border-radius: 12% 88% 76% 24% / 19% 50% 50% 81%  !important;
      background-image: url("../../assets/images/features.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 400px;
    }
    .icon-box {
      padding-left: 15px;
      i {
        font-size: 48px;
        float: left;
        color: #ffc451;
      }
      h4{
        font-size: 20px;
        font-weight: 700;
        margin: 5px 0 10px 60px;
      }
      p{
        font-size: 15px;
        color: #848484;
        margin-left: 60px;
      }
    }
  }
  .section5{
    padding: 30px 0;
    background: url(../../assets/images/testimonials-bg.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
    }
  }
  .section6{
    background: #fff;
    padding: 60px 0;
    .member{
      margin-bottom: 20px;
      overflow: hidden;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      .member-img{
        position: relative;
        overflow: hidden;
      }
      .member-info{
        padding: 25px 15px;
        h4{
          font-weight: 700;
          margin-bottom: 5px;
          font-size: 18px;
          color: #151515;
        }
        span{
          display: block;
          font-size: 13px;
          font-weight: 400;
          color: #aaaaaa;
        }
      }
    }
  }
  .section7{
    padding: 60px 0;
    .imageCount{
      background: url(../../assets/images/counts-img.jpg) center center no-repeat;
      background-size: cover;
      min-height: 400px;
    }
    .content{
      padding: 30px 0;
      h3{
        font-weight: 700;
        font-size: 34px;
        color: #151515;
      }
      p{
        margin: 0;
      }
      .count-box{
        padding: 20px 0;
        width: 100%;
        i{
          display: block;
          font-size: 36px;
          color: #ffc451;
          float: left;
        }
        span{
          font-size: 36px;
          line-height: 30px;
          display: block;
          font-weight: 700;
          color: #151515;
          margin-left: 50px;
        }
        p{
          padding: 15px 0 0 0;
          margin: 0 0 0 50px;
          font-family: "Raleway", sans-serif;
          font-size: 14px;
          color: #3b3b3b;

        }
      }
    }
  }
  .xxx{
    height: 3000px;
  }
}