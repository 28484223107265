.taxCreditWorkModal{
  .modal-header{
    background: #ffc451;
    padding-top: 10px;
    padding-bottom: 10px;
    .modal-title{
      font-weight: bold;
    }
  }
  .title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .desc{
    font-size: 14px;
  }
}