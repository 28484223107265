.reviewSlider {
  width: 100%;
  height: 300px;
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .testimonial-item{
      text-align: center;
      color: #fff;
      h3{
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0 5px 0;
        color: #fff;
      }
      h4{
        font-size: 16px;
        color: #ddd;
        margin: 0 0 15px 0;
      }
      p{
        font-style: italic;
        margin: 0 auto 15px auto;
        color: #eee;
        .quote-icon-left{
          display: inline-block;
          left: -5px;
          position: relative;
          color: rgba(255, 255, 255, 0.6);
          font-size: 26px;
        }
        .quote-icon-right{
          isplay: inline-block;
          right: -5px;
          position: relative;
          top: 10px;
          color: rgba(255, 255, 255, 0.6);
          font-size: 26px;
        }
      }
    }
  }
  .swiper-pagination-bullet-active{
    color:#ffc451;
    background-color: #ffc451;
  }
  @media (max-width: 576px){
    height: 400px;
  }
  @media (max-width: 490px){
    height: 530px;
  }
}
