@import url('assets/fonts/fonts.scss');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*{
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Lato', sans-serif;
}
.App{
  position: relative;
}
a{
  text-decoration: none;
}
span.lazy-load-image-background{
  display: block;
  width: 100%;
  height: 100%;
}

