.footer{
  background: black;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
  .footer-logo{
    width: 60%;
    display: flex;
    margin: 0 auto 30px;
    @media (max-width: 786px) {
      max-width: 240px;
      width: 100%;
    }
  }
  .footerTop{
    background: #151515;
    border-bottom: 1px solid #222222;
    padding: 30px 0 30px 0;
    .social-links-title{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      text-decoration: underline;
      text-underline-offset: 2px;
      margin-left: 16px;
    }
    .social-links{
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      @media (max-width: 786px) {
        margin-bottom: 30px;
      }
      a{
        font-size: 18px;
        display: flex;
        align-items: center;
        color: #fff;
        line-height: 1;
        padding: 4px 0;
        text-align: center;
        text-decoration: none;
        &>div{
          display: flex;
          align-items: center;
          justify-content: center;
          background: #292929;
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 4px;
          transition: all 0.3s;
        }
        &:hover{
          color: #ffffff;
        }
        &.facebook{
          &:hover{
            &>div{
              background: #0a53be;
            }
          }
        }
        &.twitter{
          &>div{
            svg{
              width: 12px !important;
              height: 12px !important;
              path{
                fill: #ffffff;
              }
            }
          }
          &:hover{
            &>div{
              background: #000000;
            }
          }
        }
        &.instagram{
          &:hover{
            &>div{
              background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            }
          }
        }
        &.linkedin{
          &:hover{
            &>div{
              background: #0a53be;
            }
          }
        }
        &.youtube{
          &:hover{
            &>div{
              background: #ff0000;
            }
          }
        }
      }
    }
    .footer-info{
      @media(max-width: 786px){
        max-width: 240px;
        width: 100%;
        margin: 0 auto 20px;
      }
      p{
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: "Raleway", sans-serif;
        color: #fff;
        &.contact-info{
          display: flex;
          a{
            color: #ffc451;
            display: flex;
            align-items: flex-start;
            transition: all 150ms ease-in-out;
            margin-bottom: 20px;
            img{
              height: auto !important;
            }
            span{
              display: block;
              line-height: normal;
              margin-left: 7px;
            }
            &:hover{
              transform: scale(1.01);
            }
          }
          &:last-child{
            a{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .footer-links{
      padding-top: 30px;
      ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        li{
          padding: 10px 5px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          @media(max-width: 767px){
            justify-content: start;
          }
          a{
            color: #fff;
            transition: 0.3s;
            display: inline-block;
            line-height: 1;
            text-align: center;
          }
          &:hover{
            a{
              color: #ffc451;
            }
          }
        }
      }
    }
  }
  .footerBottom{
    .copyright{
      text-align: center;
      padding-top: 30px;
    }
    .credits{
      padding-top: 10px;
      text-align: center;
      font-size: 13px;
      color: #fff;
      a{
        color: #ffc451;
        text-decoration: none;
      }
    }
  }
}