.inner-page{
  padding: 110px 0 60px;
  overflow: hidden;
  .member{
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    .member-img{
      position: relative;
      overflow: hidden;
    }
    .member-info{
      padding: 25px 15px;
      p{
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 18px;
        color: #151515;
      }
      span{
        display: block;
        font-size: 13px;
        font-weight: 400;
        color: #aaaaaa;
      }
    }
  }
}