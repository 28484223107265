.billOffsetModal{
  .modal-header{
    background: #ffc451;
    padding-top: 10px;
    padding-bottom: 10px;
    .modal-title{
      font-weight: bold;
    }
  }

}