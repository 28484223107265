@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//Lato

@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-Thin.ttf);
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-Light.ttf);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-Italic.ttf);
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-Black.ttf);
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url(Lato/Lato-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

//Raleway

@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-Thin.ttf);
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-Light.ttf);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-Regular.ttf);
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-Black.ttf);
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(Raleway/Raleway-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}